<template>
  <v-dialog
      v-model="show"
      width="500"
      persistent
  >
    <v-card>
      <v-card-title class="red darken-2 headline" style="color: white">
        User Logout
      </v-card-title>

      <v-card-text class="mt-3">
        Are you sure you want to logout?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel-logout')">
          Cancel
        </v-btn>
        <v-btn color="red darken-2 " @click="$emit('proceed-logout')" style="color: white">
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
name: "Logout",
  props : {
    show : {
      type : Boolean
    }
  }
}
</script>

<style scoped>

</style>