<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="mini" app>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon> mdi-account </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list nav dense rounded>
        <v-list-item v-for="(item, i) in currentItems" :key="i" :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--      <template v-slot:append>-->
      <!--        <div class="pa-2">-->
      <!--          <v-btn block>-->
      <!--            <auto-logout/>-->
      <!--          </v-btn>-->
      <!--        </div>-->
      <!--      </template>-->
    </v-navigation-drawer>

    <v-app-bar app dark dense color="red darken-2">
      <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>
      <v-toolbar-title>Partner Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list nav dense rounded>
          <v-list-item link :to="'/profile'">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="show = !show">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
    <Logout
      :show="show"
      @cancel-logout="show = !show"
      @proceed-logout="logoutUser"
      @click:outside="show = !show"
    />
    <v-idle @idle="logoutUser" :duration="3600" class="invisible" />
    <auto-logout class="invisible" @timeout="logoutUser" />
  </v-app>
</template>

<script>
import navItems from '../container/_nav.js';
import Logout from "../components/User/Logout";
import AutoLogout from "../components/views/AutoLogout";
export default {
  name: "MainLayout",
  components: { AutoLogout, Logout },
  data() {
    return {
      mini: false,
      show: false,
      nav: navItems
    };
  },
  created(){
    if (this.access.length == 0){
      this.$store.dispatch('getAccess')
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    access() {
      return this.$store.getters.getAccess;
    },
    currentItems() {
      return this.nav.filter((item) => {
        let found = false;
        if (item.access) {
          for (var i = 0; i < item.access.length; i++) {
            if (this.access.indexOf(item.access[i]) != -1) {
              return true;
            }
          }
        }
        return !item.access || found;
      });
    },
    computedSidebar() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.currentItems,
        },
      ];
    },
  },
  methods: {
    minify() {
      this.mini = !this.mini;
    },
    logoutUser() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>