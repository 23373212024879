
export default [
    {
      to: "/dashboard",
      title: "Content",
      icon: "mdi-view-dashboard",
    },
    {
        to : '/search',
        title : 'Search',
        icon : 'mdi-magnify',
        access: ["search"]
    },
    {
      to: "/monetization",
      title: "Monetization",
      icon: "mdi-currency-usd",
      access: ["monetization"]
    },
]