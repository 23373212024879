<template>
  <div>
    Timer : {{displayHours}} : {{displayMinutes}} : {{displaySeconds}}
  </div>
</template>

<script>
export default {
  name: "AutoLogout",
  data: () => ({
    displayHours: 0,
    displayMinutes: 0,
    displaySeconds: 0,
  }),
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    end() {
      return new Date(this.$store.getters.getTokenExpiry)
    }
  },
  mounted() {
    this.runTimer()
  },
  methods: {
    formatNum: num => (num < 10 ? "0" + num : num),
    runTimer() {
      const timer = setInterval(() => {
        const now = new Date().getTime()
        const distance = this.end.getTime() - now
        if (distance < 0) {
          clearInterval(timer)
          this.$emit('timeout')
        }
        const hours = Math.floor((distance % this._days) / this._hours)
        const mins = Math.floor((distance % this._hours) / this._minutes)
        const secs = Math.floor((distance % this._minutes) / this._seconds)
        this.displayHours = this.formatNum(hours)
        this.displayMinutes = this.formatNum(mins)
        this.displaySeconds = this.formatNum(secs)
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>